.hero {
  position: relative;
  height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

.fixedBackground {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('../../assets/background.jpg');
  background-size: cover;
  background-position: center;
  z-index: 1;
  transition: opacity 0.2s ease-out;
}

.contentContainer {
  margin-top: -150px!important;
  position: relative;
  z-index: 2;
  text-align: center;
  max-width: 1200px;
  padding: 2rem;
  margin: 0 1rem;
  border-radius: 200px;
  transition: all 1s ease;
  background: rgba(0, 0, 0, 0.329);
  box-shadow: 0 0 30px 30px rgba(0, 0, 0, 0.26);
}

.visible {
  transform: translateY(0);
  opacity: 1;
}

.hidden {
  transform: translateY(2.5rem);
  opacity: 0;
}

.heading {
  font-size: 2rem;
  font-weight: 800;
  letter-spacing: -0.025em;
  color: #d1d5db;
  margin-bottom: 1rem;
}

.headingMain {
  display: block;
  margin-bottom: 0.5rem;
}

.headingAccent {
  display: inline-block; /* Changed from block to inline-block */
  background: linear-gradient(90deg, #8B5CF6, #EC4899, #8B5CF6);
  background-size: 200% 100%;
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: flowingTextGradient 5s linear infinite;
  position: relative;
}



@keyframes flowingTextGradient {
  0% {
    background-position: 200% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.description {
  margin: 0.75rem auto;
  max-width: 48rem;
  font-size: 1rem;
  color: #d1d5db;
  line-height: 1.5;
}

.ctaContainer {
  margin-top: 2rem;
  display: flex;
  justify-content: center;
}

.ctaButton {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background-color: #8B5CF6;
  color: white;
  text-decoration: none;
  border-radius: 0.475rem;
  font-weight: 600;
  transition: background-color 0.2s ease;
}

.ctaButton:hover {
  background-color: #7c4ef3;
}

/* Mobile-first approach */
@media (max-width: 639px) {
  .contentContainer {
    margin-top: 20px;
    padding: 1.5rem;
    margin: 0 0rem;
    border-radius: 30px;
    box-shadow: 0 0 20px 20px rgba(0, 0, 0, 0.26);
  }
  
  .heading {
    font-size: 1.5rem;
  }
  
  .description {
    font-size: 0.8rem;
    margin: 0.5rem auto;
  }
  
  .ctaButton {
    padding: 0.6rem 1.2rem;
    font-size: 0.9rem;
  }
  
  .ctaContainer {
    margin-top: 1.5rem;
  }
}

/* Tablet */
@media (min-width: 640px) and (max-width: 767px) {
  .contentContainer {
    padding: 1.75rem;
    border-radius: 50px;
  }
  
  .heading {
    font-size: 1.8rem;
  }
  
  .description {
    font-size: 1rem;
  }
}

/* Desktop */
@media (min-width: 768px) {
  .heading {
    font-size: 2.5rem;
  }
  
  .description {
    font-size: 1.25rem;
    margin-top: 1.25rem;
  }
  
  .ctaContainer {
    margin-top: 2rem;
  }
}

/* Large screens */
@media (min-width: 1024px) {
  .contentContainer {
    padding: 2.5rem;
  }
  
  .heading {
    font-size: 3rem;
  }
  
  .description {
    font-size: 1.35rem;
  }
}