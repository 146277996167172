:root {
    --evt-dynamic-font-size: calc(1rem + 6.5vw);
    --evt-margin-left-small-screen: calc(2vw);
}

.evt-wrapper {
    overflow-x: hidden;
    position: relative;
    padding: 0;
    background-color: transparent;
}

.evt-container {
    display: flex;
    flex-wrap: nowrap;
    width: fit-content;
    height: 100vh;
    gap: 32px;
    background-color: transparent;
    position: relative;
    z-index: 3;
    padding: 0 2rem;
}

.evt-container .evt-section {
    flex: 0 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2rem;
    width: 1300px;
    min-width: 320px;
}

.evt-title {
    font-size: 3.5rem;
    font-weight: 800;
    margin-bottom: 3rem;
    margin-top: -70px;
    background: linear-gradient(45deg, #8B5CF6, #EC4899, #8B5CF6);
    background-size: 200% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: evt-flowing-gradient 3s linear infinite;
    position: relative;
    display: inline-block;
}

.evt-main {
    display: flex;
    flex-direction: column;
    background-color: transparent;
    min-height: 100vh;
    width: 100%;
    position: relative;
}

.evt-content {
    position: relative;
    z-index: 3;
}

.evt-heading {
    text-align: center;
    margin: 3rem 0;
    position: relative;
    z-index: 3;
}

.evt-heading div {
    font-family: 'Courier New', Courier, monospace;
    font-size: 3.5rem;
    font-weight: 800;
    background: linear-gradient(45deg, #8B5CF6, #EC4899, #8B5CF6);
    background-size: 200% 100%;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: evt-flowing-gradient 3s linear infinite;
    position: relative;
    display: inline-block;
}

@keyframes evt-flowing-gradient {
    0% {
        background-position: 200% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.evt-sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
}

@media (min-width: 1200px) {
    .evt-container .evt-section {
        width: 1200px;
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .evt-container .evt-section {
        width: 800px;
        
    }
}

@media (max-width: 767px) {
    .evt-wrapper {
        padding: 0;
    }

    .evt-container {
        display: flex;
        flex-wrap: nowrap;
        width: fit-content;
        height: 10vh;
        padding: 0 1rem;
        height: auto;
        min-height: 100vh;
        
    }

    .evt-container .evt-section {
        width: 0px;
        padding: 1rem;
    }

    .evt-heading div {
        font-size: 2rem;
    }
    .evt-title{
        margin-top: -100px;
        font-size: 2.0rem;
        margin-bottom: -500px;
    }
}