* {
  padding: 0;
  margin: 0;
}



.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 10vh;
}

.btn {

  text-decoration: none;
  /* border: 1px solid rgb(0, 0, 0); */
  position: relative;
  overflow: hidden;
  font-size: 1.5rem;
  padding: 1rem 3rem;
  color: #ffffff;
  /* text-transform: uppercase; */
  transition: box-shadow 0.65s ease-in-out;
  background: linear-gradient(90deg, #8B5CF6, #EC4899);
  border-radius: 90px;
}

.btn:hover {
  box-shadow: 1px 1px 25px 10px rgba(153, 153, 163, 0.671);
  background-color: #8B5CF6;
}

.btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%; /* Start off-screen to the left */
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.671),
    transparent
  );
  transition: left 0.65s ease-in-out; /* Transition for shine effect */
}

.btn:hover::before {
  left: 100%; /* Move shine effect to the right on hover */
}
@media (max-width: 768px) {
  .btn {
    font-size: 1rem;
    padding: 0.6rem 1.5rem;
    max-width: 80%;
    text-align: center;
  }
}

.btn:hover {
  box-shadow: 1px 1px 25px 10px rgba(153, 153, 163, 0.671);
  background-color: #8B5CF6;
}

.btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    120deg,
    transparent,
    rgba(255, 255, 255, 0.671),
    transparent
  );
  transition: left 0.65s ease-in-out;
}

.btn:hover::before {
  left: 100%;
}