@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@400;500&display=swap');

.footer {
  background: linear-gradient(to right, rgba(44, 0, 62, 0.9), rgba(19, 0, 43, 0.9));
  backdrop-filter: blur(12px);
  -webkit-backdrop-filter: blur(12px);
  padding: 3rem 2rem;
  color: white;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.footer-content {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
}

.footer-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
}

.footer-logo {
  height: 60px;
  transition: transform 0.3s ease;
}

.footer-logo:hover {
  transform: scale(1.05);
}

.footer-text {
  font-family: 'Montserrat', monospace;
  font-size: 0.9rem;
  color: rgba(255, 255, 255, 0.8);
  max-width: 400px;
  line-height: 1.6;
}

.social-links {
  display: flex;
  gap: 1.5rem;
  margin-top: 0.5rem;
}

.social-link {
  color: white;
  font-size: 1.5rem;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.social-link:hover {
  color: #6738b2;
  transform: translateY(-3px);
  background: rgba(255, 255, 255, 0.2);
  border-color: #6738b2;
  box-shadow: 0 5px 15px rgba(103, 56, 178, 0.3);
}

.footer-right {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1rem;
}

.footer-links {
  display: flex;
  gap: 2rem;
}

.footer-link {
  font-family: 'Roboto Mono', monospace;
  color: white;
  text-decoration: none;
  font-size: 0.9rem;
  transition: color 0.3s ease;
  position: relative;
}

.footer-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 1px;
  bottom: -2px;
  left: 0;
  background-color: #6738b2;
  transition: width 0.3s ease;
}

.footer-link:hover {
  color: #6738b2;
}

.footer-link:hover::after {
  width: 100%;
}

.copyright {
  font-family: 'Montserrat', monospace;
  font-size: 0.8rem;
  color: rgba(255, 255, 255, 0.6);
}

@media (max-width: 768px) {
  .footer-content {
    flex-direction: column;
    text-align: center;
    gap: 2rem;
  }

  .footer-left {
    align-items: center;
  }

  .footer-right {
    align-items: center;
  }

  .footer-links {
    flex-direction: column;
    gap: 1rem;
    align-items: center;
  }

  .footer-text {
    text-align: center;
  }

  .social-links {
    justify-content: center;
  }
}