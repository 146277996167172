@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Trocchi&display=swap");

.about {
  text-align: center;
  padding: 50px 20px;
  background: radial-gradient(circle at center, rgba(103, 56, 178, 0.15) 0%, rgba(10, 10, 10, 1) 100%);
  color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
}

.about::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: 
    radial-gradient(circle at 20% 20%, rgba(103, 56, 178, 0.4) 0%, transparent 25%),
    radial-gradient(circle at 80% 80%, rgba(103, 56, 178, 0.4) 0%, transparent 25%);
  pointer-events: none;
  opacity: 0.5;
}

.title {
  font-family: "Montserrat", sans-serif;
  font-size: 4rem;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 30px;
  position: relative;
  background: linear-gradient(45deg, #fff, #6738b2);
  -webkit-background-clip: text;

}

/* .title::after {
  content: "";
  display: block;
  width: 50px;
  height: 3px;
  background: linear-gradient(to right, #6738b2, #13002b);
  margin: 10px auto;
  box-shadow: 0 0 10px rgba(103, 56, 178, 0.5);
} */

.content {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
  width: 100%;
  max-width: 1300px;
  padding: 0 20px;
  position: relative;
  z-index: 1;
}

.box {
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
  background: rgba(103, 56, 178, 0.1);
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  box-shadow: 0 4px 20px rgba(103, 56, 178, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  border: 1px solid rgba(103, 56, 178, 0.2);
  backdrop-filter: blur(5px);
}

.box:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 30px rgba(103, 56, 178, 0.3);
  border-color: rgba(103, 56, 178, 0.4);
  background: rgba(103, 56, 178, 0.15);
}

.image-container {
  flex-shrink: 0;
  width: 270px;
}

.box img {
  width: 100%;
  height: 320px;
  border-radius: 10px;
  object-fit: cover;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
  transition: transform 0.3s ease;
  border: 2px solid rgba(103, 56, 178, 0.3);
}

.box img:hover {
  transform: scale(1.05);
}

.box p {
  font-size: 1.5rem;
  font-family: "Montserrat", serif;
  text-align: justify;
  line-height: 1.6;
  color: #f0f0f0;
  margin: 0;
}

.box b {
  color: #6738b2;
  text-shadow: 0 0 10px rgba(103, 56, 178, 0.3);
}

/* Tablet Styles */
@media (max-width: 1024px) {
  .title {
    font-size: 3rem;
  }

  .content {
    max-width: 90%;
  }

  .box {
    padding: 15px;
  }

  .box p {
    font-size: 1.5rem;
  }

  .image-container {
    width: 200px;
  }

  .box img {
    height: 240px;
  }
}

/* Mobile Styles */
@media (max-width: 768px) {
  .about{
    background-color: black;
  }
  .title {
    font-size: 2rem;
  }

  .content {
    max-width: 100%;
    padding: 0 10px;
  }

  .box {
    position: relative;
    padding: 20px;
    gap: 0;
    min-height: 300px;
    background: none;
    overflow: hidden;
  }

  .box::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(103, 56, 178, 0.3));
    z-index: 1;
    border-radius: 10px;
  }

  .image-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-width: none;
  }

  .box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transform: none;
    border-radius: 0px;
  }

  .box img:hover {
    transform: none;
  }

  .box p {
    position: relative;
    z-index: 2;
    font-size: 1.2rem;
    text-align: center;
    padding: 20px;
    margin: 0;
    color: white;
  }
}

/* Small Mobile Styles */
@media (max-width: 480px) {
  .title {
    font-size: 1.75rem;
  }

  .box {
    min-height: 250px;
  }

  .box p {
    font-size: 1rem;
    padding: 15px;
  }
}