@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@700&family=Trocchi&display=swap");

.timeline-container {
  max-width: 1800px;
  margin: 0 auto;
  padding: 4rem 1rem;
  min-height: 100vh;
}

.timeline-filters {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-bottom: 4rem;
  flex-wrap: wrap;
}

.filter-button {
  padding: 0.75rem 2rem;
  border: 2px solid #6738b2;
  border-radius: 0.5rem;
  background: transparent;
  color: #6738b2;
  font-family: 'Roboto Mono', monospace;
  font-size: 1.5rem;
  cursor: pointer;
  transition: all 0.3s ease;
  min-width: 120px;
}

.filter-button:hover {
  background: rgba(29, 213, 136, 0.1);
}

.filter-button.active {
  background: #6738b2;
  color: white;
}

.timeline {
  position: relative;
  margin: 0 auto;
  max-width: 1200px;
  padding: 0 2rem;
}

.timeline-name {
  font-family: "Montserrat", serif;
  text-align: center;
  padding-bottom: 0px;
  font-weight: 900;
  font-size: 20px;
  margin-bottom: 60px;
}

.timeline-desc {
  font-family: "Montserrat", serif;
  text-align: center;
  padding-bottom: 0px;
  font-weight: 900;
  font-size: 60px;
  margin-bottom: 60px;
  margin-top:300px;
}

/* Vertical line */
.timeline::before {
  content: '';
  position: absolute;
  left: 50%;
  top: 0;
  bottom: 0;
  width: 2px;
  background-color: #6738b2;
  transform: translateX(-50%);
}

.timeline-event {
  position: relative;
  margin: 6rem 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeline-event.odd {
  padding-right: 52%;
}

.timeline-event.even {
  padding-left: 52%;
}

.event-content {
  width: 100%;
  position: relative;
}

.event-card {
  background: rgba(41, 41, 41, 0.603);
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: row;
  width: 800px;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.event-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3);
  background: rgba(255, 255, 255, 0.05);
}

.event-imagex {
  width: 50%;
  height: 50%;
  flex-direction: row;
  object-fit: cover;
  margin-left: 0px;
}

.event-details {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  position: relative;
  min-height: 280px;
}

.event-content-wrapper {
  flex: 1;
}

.event-title {
  font-family: "Montserrat", sans-serif;
  font-size: 1.5rem;
  font-weight: 700;
  color: #ffffff;
  margin: 0;
  margin-bottom: 0.5rem;
}

.event-date {
  font-family: 'Roboto Mono', monospace;
  text-align: center;
  font-size: 1rem;
  color: #1dd588;
  margin: 0;
  margin-bottom: 1rem;
}

.event-description {
  /* font-family: 'Calibri',serif; */
  color: #f0f0f0;
  line-height: 1.6;
  font-size: 1.1rem;
  margin: 0;
  margin-bottom: 2rem;
}

.register-button {
  display: inline-block;
  padding: 0.75rem 1.5rem;
  background: linear-gradient(to right, #2c003e, #13002b);
  color: white;
  text-decoration: none;
  border-radius: 50px;
  font-family: 'Roboto Mono', monospace;
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.2);
  text-align: center;
  width: fit-content;
  position: absolute;
  bottom: 1.5rem;
  left: 1.5rem;
  align-self: center;
}

.register-button:hover {
  background: linear-gradient(to right, #3c005e, #23004b);
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
}

.timeline-connector {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  background-color: #6738b2;
  border: 3px solid #fff;
  border-radius: 50%;
  z-index: 2;
  box-shadow: 0 0 0 4px rgba(29, 213, 136, 0.2);
  transition: all 0.3s ease;
}

.timeline-event:hover .timeline-connector {
  background-color: #6738b2;
  transform: translate(-50%, -50%) scale(1.1);
}

/* Large screens */
@media (min-width: 1600px) {
  .timeline {
    max-width: 1400px;
  }

  .event-image {
    height: 320px;
  }

  .event-details {
    padding: 2rem;
    min-height: 320px;
  }

  .event-title {
    font-size: 1.75rem;
  }

  .event-description {
    font-size: 1.2rem;
  }

  .register-button {
    bottom: 2rem;
    left: 2rem;
  }
}

/* Mobile styles */
@media (max-width: 1920px) {
  .timeline-name {
    font-size: 4rem;
  }
  .timeline-container {
    padding: 2rem 1rem;
  }

  .timeline {
    padding: 0;
  }

  .timeline::before {
    left: 0rem;
  }

  .timeline-event {
    margin: 4rem 0;
    padding: 0 !important;
  }

  .event-content {
    margin-left: 3rem;
    width: calc(100% - 3rem);
  }

  .event-image {
    height: 200px;
  }

  .event-details {
    padding: 1.5rem;
    min-height: 200px;
  }

  .event-title {
    font-size: 1.25rem;
  }

  .event-date {
    font-size: 0.9rem;
  }

  .event-description {
    font-size: 1rem;
  }

  .register-button {
    position: relative;
    bottom: auto;
    left: auto;
    margin-top: 1rem;
  }

  .timeline-connector {
    left: 1rem;
    width: 20px;
    height: 20px;
  }

  .timeline-filters {
    flex-direction: column;
    align-items: stretch;
    gap: 0.75rem;
    padding: 0 1rem;
  }

  .filter-button {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .timeline-name {
    font-size: 2rem;
    text-align: center;
  }

  .timeline-container {
    padding: 2rem 0.5rem;
  }

  .timeline::before {
    left: 0rem;
  }

  .timeline-event {
    margin: 3rem 0;
    padding: 0;
  }

  .event-content {
    margin-left: 2rem;
    width: calc(100% - 2rem);
  }

  .event-card {
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    padding: 1rem;
  }

  .event-imagex {
    display: block;  /* Ensures the image behaves like a block-level element */
    margin: 0 auto;  /* Horizontally centers the image */
    width: 70%;     /* Makes sure the image takes up 100% width of its container */
    height: auto;    /* Preserves the aspect ratio */
    object-fit: contain;  /* Ensures the image fits within its bounds without distortion */
  }

  .event-details {
    padding: 1rem;
    min-height: auto;
  }

  .event-title {
    font-size: 1.5rem !important;
    text-align: left;
  }

  .event-date {
    font-size: 1rem;
    text-align: left;
  }

  .event-description {
    font-size: 0.7rem !important;
    margin-bottom: 1rem;
    text-align: left;
  }

  .register-button {
    position: relative;
    display: block;
    width: 100%;
    text-align: center;
    margin: 1rem 0;
    padding: 0.75rem;
  }

  .timeline-connector {
    left: 0.5rem;
    width: 18px;
    height: 18px;
  }

  .timeline-filters {
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    width: 100%;
  }

  .filter-button {
    width: 100%;
    font-size: 1rem;
  }
}
