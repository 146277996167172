.ecell-footer {
  background: rgba(0, 0, 0, 0.9);
  color: white;
  padding: 3rem 0 1rem;
  position: relative;
  width: 100%;
  text-align: center;
  z-index: 999;
}

.ecell-footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(45deg, #8B5CF6, #EC4899);
}

.ecell-footer-container {
  max-width: 800px;  /* Reduced width */
  margin: 0 auto;
  padding: 0 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
}

/* Logo */
.ecell-footer-logo {
  width: 60px;
  height: auto;
  margin-bottom: 10px;
}

/* Text */
.ecell-footer-description {
  color: #a0aec0;
  max-width: 500px;
  line-height: 1.6;
}

/* Quick Links */
.ecell-footer-links {
  display: flex;
  gap: 1.5rem;
}

.ecell-footer-link {
  color: #a0aec0;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
}

.ecell-footer-link:hover {
  color: #8B5CF6;
}

/* Social Icons */
.ecell-social-links {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.ecell-social-links a {
  color: white;
  background: rgba(255, 255, 255, 0.1);
  padding: 0.5rem;
  border-radius: 50%;
  transition: all 0.3s ease;
}

.ecell-social-links a:hover {
  background: #8B5CF6;
  transform: translateY(-2px);
}

/* Footer Bottom */
.ecell-footer-bottom {
  text-align: center;
  margin-top: 2.5rem;
  padding-top: 1.2rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  color: #a0aec0;
  font-size: 0.9rem;
}

/* Responsive Design */
@media (max-width: 768px) {
  .ecell-footer-container {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }
  
  .ecell-footer-links {
    flex-direction: column;
    gap: 1rem;
  }
  
  .ecell-social-links {
    justify-content: center;
  }
}