.event-brochure {
    min-height: 100vh;
    padding: 80px 20px;
    color: white;
    position: relative;
  }
  
  .brochure-content {
    max-width: 1200px;
    margin: 0 auto;
    background: rgba(41, 41, 41, 0.603);
    backdrop-filter: blur(3px);
    -webkit-backdrop-filter: blur(3px);
    border-radius: 20px;
    padding: 40px;
    box-shadow: 0 0 30px rgba(103, 56, 178, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.1);
  }
  
  .event-title {
    font-family: "Montserrat", sans-serif;
    font-size: 3rem;
    text-align: center;
    margin-bottom: 40px;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .event-section {
    margin-bottom: 40px;
  }
  
  .event-section h2 {
    font-family: "Montserrat", sans-serif;
    font-size: 1.8rem;
    color: #6738b2;
    margin-bottom: 20px;
    border-bottom: 2px solid rgba(103, 56, 178, 0.3);
    padding-bottom: 10px;
  }
  
  .details-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-bottom: 30px;
  }
  
  .detail-item {
    background: rgba(255, 255, 255, 0.05);
    padding: 20px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  
  .detail-label {
    font-family: "Roboto Mono", monospace;
    font-size: 0.9rem;
    color: #1dd588;
  }
  
  .detail-value {
    font-family: "Montserrat", sans-serif;
    font-size: 1.1rem;
  }
  
  .event-description {
    font-family: "Montserrat", serif;
    font-size: 1.2rem;
    line-height: 1.8;
    color: #f0f0f0;
  }
  
  .highlights-list {
    list-style: none;
    padding: 0;
  }
  
  .highlights-list li {
    font-family: "Montserrat", serif;
    font-size: 1.1rem;
    margin-bottom: 15px;
    padding-left: 25px;
    position: relative;
  }
  
  .highlights-list li::before {
    content: "•";
    color: #6738b2;
    font-size: 1.5rem;
    position: absolute;
    left: 0;
    top: -5px;
  }
  
  .schedule-timeline {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .schedule-item {
    display: flex;
    align-items: center;
    gap: 20px;
    background: rgba(255, 255, 255, 0.05);
    padding: 15px 20px;
    border-radius: 10px;
    transition: transform 0.3s ease;
  }
  
  .schedule-item:hover {
    transform: translateX(10px);
    background: rgba(255, 255, 255, 0.1);
  }
  
  .schedule-time {
    font-family: "Roboto Mono", monospace;
    color: #1dd588;
    min-width: 100px;
  }
  
  .schedule-event {
    font-family: "Montserrat", sans-serif;
  }
  
  .registration-details {
    background: rgba(255, 255, 255, 0.05);
    padding: 30px;
    border-radius: 10px;
    text-align: center;
  }
  
  .register-button {
    display: inline-block;
    margin-top: 20px;
    padding: 12px 30px;
    background: linear-gradient(to right, #2c003e, #13002b);
    color: white;
    text-decoration: none;
    border-radius: 50px;
    font-family: "Roboto Mono", monospace;
    font-weight: 500;
    transition: all 0.3s ease;
    border: 1px solid rgba(255, 255, 255, 0.2);
  }
  
  .register-button:hover {
    transform: translateY(-3px);
    box-shadow: 0 5px 15px rgba(103, 56, 178, 0.4);
    background: linear-gradient(to right, #3c005e, #23004b);
  }
  
  .contact-details {
    display: flex;
    justify-content: center;
    gap: 40px;
    flex-wrap: wrap;
  }
  
  .contact-details p {
    font-family: "Roboto Mono", monospace;
  }
  
  .event-not-found {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Montserrat", sans-serif;
    font-size: 2rem;
    color: #ffffff;
  }
  
  @media (max-width: 768px) {
    .event-title {
      font-size: 2rem;
    }
  
    .event-section h2 {
      font-size: 1.5rem;
    }
  
    .brochure-content {
      padding: 20px;
    }
  
    .details-grid {
      grid-template-columns: 1fr;
    }
  
    .schedule-item {
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
  
    .contact-details {
      flex-direction: column;
      gap: 20px;
      align-items: center;
    }
  }