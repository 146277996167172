@import url('https://fonts.cdnfonts.com/css/lovelo');
.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.preloader.slide-up {
  transform: translateY(-100%);
  transition: transform 1s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.preloader-text {
  position: absolute;
  font-size: 4.5rem;
  font-weight: 900;
  color: white;
  opacity: 0;
  letter-spacing: 10px !important;
  font-family: 'lovelo', sans-serif;
  text-align: center;
  background: linear-gradient(90deg, #8B5CF6, #EC4899);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  pointer-events: none;
  transition: opacity 0.2s ease-in-out;
}

.preloader-text.visible {
  opacity: 1;
}

.preloader-text.hidden {
  opacity: 0;
}

.preloader-image.first {
  width: 290px;
  height: 150px;
  z-index: 2;
  opacity: 0;
  transform: translateX(-300px);
  animation: fadeInSlideLeft 2.5s ease forwards;
  display: none;
  margin-left: -20px;
  
}

.preloader-image.second {
  width: 180px;
  height: 100px;
  z-index: 1;
  opacity: 0;
  transform: translateX(-40px);
  animation: fadeInSlideRight 1.5s ease 1s forwards;
  display: none;
}

.preloader-image.show {
  display: block;
}

@keyframes fadeInSlideLeft {
  0% {
    opacity: 0;
    transform: translateX(0%);
  }
  40% {
    opacity: 1;
    transform: translateX(0%);
  }
  100% {
    opacity: 1;
    transform: translateX(-70px);
  }
}

@keyframes fadeInSlideRight {
  0% {
    opacity: 0;
    transform: translateX(-100%);
  }
  100% {
    opacity: 1;
    transform: translateX(-70px);
  }
}

/* Mobile Responsive Adjustments */
@media screen and (max-width: 768px) {
  .preloader-text {
    font-size: 2.5rem;
    letter-spacing: 5px !important;
  }
  
  .preloader-image.first {
    width: 190px;
    height: 100px;
  }
  
  .preloader-image.second {
    width: 120px;
    height: 70px;
  }
  
  @keyframes fadeInSlideLeft {
    0% {
      opacity: 0;
      transform: translateX(0%);
    }
    40% {
      opacity: 1;
      transform: translateX(0%);
    }
    100% {
      opacity: 1;
      transform: translateX(-45px);
    }
  }
  
  @keyframes fadeInSlideRight {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(-40px);
    }
  }
}

/* Small Mobile Devices */
@media screen and (max-width: 480px) {
  .preloader-text {
    font-size: 1.8rem;
    letter-spacing: 3px !important;
  }
  
  .preloader-image.first {
    width: 150px;
    height: 80px;
  }
  
  .preloader-image.second {
    width: 90px;
    height: 50px;
  }
  
  @keyframes fadeInSlideLeft {
    0% {
      opacity: 0;
      transform: translateX(0%);
    }
    40% {
      opacity: 1;
      transform: translateX(0%);
    }
    100% {
      opacity: 1;
      transform: translateX(-35px);
    }
  }
  
  @keyframes fadeInSlideRight {
    0% {
      opacity: 0;
      transform: translateX(-100%);
    }
    100% {
      opacity: 1;
      transform: translateX(-30px);
    }
  }
}