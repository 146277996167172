/* Import Roboto Mono from Google Fonts */
@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:wght@100;300;400;500;700&display=swap');

.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: linear-gradient(to right, rgba(44, 0, 62, 0.8), rgba(19, 0, 43, 0.8));
  padding: 15px 30px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  z-index: 1000;
  border-bottom: 0px solid white;
  transition: transform 0.3s ease-in-out;
  backdrop-filter: blur(12px);
}

.navbar-hidden {
  transform: translateY(-100%);
}

.logo {
  height: 40px;
  margin-right: 50px;
  transition: transform 0.3s ease;
}

.logo:hover {
  transform: scale(1.05);
}

.nav-list {
  display: flex;
  gap: 30px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.nav-item {
  font-family: 'Roboto Mono', monospace;
  font-weight: 500;
  font-size: 20px;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  padding: px 16px;
  border-radius: 4px;
}

.nav-item:hover {
  color: #d3d3d3;
  background: rgba(255, 255, 255, 0.1);
}

.hamburger {
  display: none;
  flex-direction: column;
  gap: 6px;
  cursor: pointer;
  z-index: 1001;
}

.hamburger span {
  display: block;
  width: 30px;
  height: 3px;
  background-color: white;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 1008px){
  .navbar {
    padding: 15px 20px;
  }

  .logo {
    height: 30px;
    margin-right: 0;
  }

  .hamburger {
    display: flex;
  }

  .hamburger.active span:nth-child(1) {
    transform: rotate(45deg) translate(8px, 8px);
  }

  .hamburger.active span:nth-child(2) {
    opacity: 0;
  }

  .hamburger.active span:nth-child(3) {
    transform: rotate(-45deg) translate(8px, -8px);
  }

  .nav-list {
    position: fixed;
    top: 0;
    right: -100%;
    width: 70%;
    height: 100vh;
    background: linear-gradient(to right, #2c003e, #13002b);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    transition: right 0.3s ease-in-out;
    padding: 80px 0;
  }

  .nav-list.active {
    right: 0;
  }

  .nav-item {
    font-size: 18px;
    width: 80%;
    text-align: center;
  }
}