.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.shutter {
  position: fixed;
  width: 50%;
  height: 100vh;
  background: #6738b2;
  transition: transform 0.8s cubic-bezier(0.77, 0, 0.175, 1);
}

.shutter-left {
  left: 0;
  transform-origin: left;
}

.shutter-right {
  right: 0;
  transform-origin: right;
}

.shutter.open {
  transform: scaleX(0);
}

.loading-text {
  position: absolute;
  color: #0a0a0a;
  font-family: 'Montserrat', sans-serif;
  font-size: 2rem;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  z-index: 10000;
  transition: opacity 0.3s ease;
}

.loading-text.fade {
  opacity: 0;
}

@media (max-width: 768px) {
  .loading-text {
    font-size: 1.5rem;
  }
}