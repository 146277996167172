.startup-expo-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  padding: 20px 0;
  transition: all 0.3s ease;
  
}

.startup-expo-navbar--scrolled {
  background-color: rgba(20, 10, 40, 0.95);
  padding: 15px 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(10px);
}

.startup-expo-navbar__container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.startup-expo-navbar__logo {
  text-decoration: none;
  display: flex;
  align-items: center;
}

.startup-expo-navbar__logo img {
  height: 40px;
  width: auto;
  transition: all 0.3s ease;
}

.startup-expo-navbar--scrolled .startup-expo-navbar__logo img {
  height: 35px; /* Slightly smaller when navbar is scrolled */
}

.startup-expo-navbar__menu {
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  align-items: center;
}

.startup-expo-navbar__item {
  margin: 0 15px;
}

.startup-expo-navbar__link {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 500;
  transition: color 0.3s ease;
  position: relative;
}

.startup-expo-navbar__link::after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  width: 0;
  height: 2px;
  background: linear-gradient(90deg, #6a11cb, #2575fc);
  transition: width 0.3s ease;
}

.startup-expo-navbar__link:hover::after,
.startup-expo-navbar__link--active::after {
  width: 100%;
}

.startup-expo-navbar__button {
  background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%);
  color: white;
  text-decoration: none;
  padding: 10px 20px;
  border-radius: 50px;
  font-weight: 500;
  transition: all 0.3s ease;
  display: inline-block;
}

.startup-expo-navbar__button:hover {
  transform: translateY(-3px);
  box-shadow: 0 5px 15px rgba(106, 17, 203, 0.4);
}

.startup-expo-navbar__menu-toggle {
  display: none;
  cursor: pointer;
  width: 30px;
  height: 20px;
  position: relative;
}

.startup-expo-navbar__menu-bar {
  width: 100%;
  height: 2px;
  background-color: white;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s ease;
}

.startup-expo-navbar__menu-bar::before,
.startup-expo-navbar__menu-bar::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: white;
  transition: all 0.3s ease;
}

.startup-expo-navbar__menu-bar::before {
  top: -8px;
}

.startup-expo-navbar__menu-bar::after {
  bottom: -8px;
}

.startup-expo-navbar__menu-bar--open {
  background-color: transparent;
}

.startup-expo-navbar__menu-bar--open::before {
  transform: rotate(45deg);
  top: 0;
}

.startup-expo-navbar__menu-bar--open::after {
  transform: rotate(-45deg);
  bottom: 0;
}

@media (max-width: 992px) {
  .startup-expo-navbar__logo img {
    height: 35px; /* Slightly smaller on mobile */
  }

  .startup-expo-navbar__menu-toggle {
    display: block;
  }
  
  .startup-expo-navbar__menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: rgba(20, 10, 40, 0.95);
    flex-direction: column;
    padding: 20px 0;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
    transition: clip-path 0.4s ease-in-out;
    backdrop-filter: blur(10px);
  }
  
  .startup-expo-navbar__menu--open {
    clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }
  
  .startup-expo-navbar__item {
    margin: 15px 0;
  }
  
  .startup-expo-navbar__item--button {
    margin-top: 20px;
  }
}

/* For very small screens */
@media (max-width: 480px) {
  .startup-expo-navbar__logo img {
    height: 30px;
  }
}