/* Import Lovelo font */
@import url('https://fonts.cdnfonts.com/css/lovelo');

.startup-expo-hero {
  height: 100vh;
  background: linear-gradient(135deg, #00000000 0%, #2d1b4e65 100%);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  position: relative;
  overflow: hidden;
}

.startup-expo-hero::before {
  content: '';
  position: absolute;
  width: 200%;
  height: 200%;
  background: rgba(255, 255, 255, 0.044);
  transform: rotate(45deg);
  top: -50%;
  left: -50%;
  animation: startup-expo-shine 8s infinite linear;
}

@keyframes startup-expo-shine {
  0% {
    transform: rotate(45deg) translateY(0);
  }
  100% {
    transform: rotate(45deg) translateY(100%);
  }
}

.startup-expo-hero__container {
  text-align: center;
  z-index: 1;
  padding: 0 20px;
  max-width: 1000px;
}

.startup-expo-hero__title {
  font-family: 'lovelo', monospace;
  font-weight: 900;
  font-size: 6.8rem;
  letter-spacing: 4px;
  margin-bottom: 0rem;
  opacity: 0;
  color: white;
  
  position: relative;
  text-shadow: 
    0 0 25px rgba(160, 115, 255, 0.8), 
    0 0 50px rgba(160, 115, 255, 0.6), 
    0 0 75px rgba(160, 115, 255, 0.4);
}


.startup-expo-hero__title--animate {
  animation: startup-expo-textReveal 1.5s cubic-bezier(0.77, 0, 0.175, 1) forwards;
}

.startup-expo-hero__title::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 100%;
  background: rgba(106, 17, 203, 0.2);
  animation: startup-expo-titleShine 2s cubic-bezier(0.77, 0, 0.175, 1) 0.5s forwards;
}

@keyframes startup-expo-titleShine {
  0% {
    width: 0;
    left: 0;
  }
  50% {
    width: 100%;
    left: 0;
  }
  100% {
    width: 0;
    left: 100%;
  }
}

@keyframes startup-expo-textReveal {
  0% {
    opacity: 0;
    transform: translateY(60px) scale(0.9);
  }
  20% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}

.startup-expo-hero__subtitle {
  font-family: 'lovelo', monospace;
  font-weight: 900;
  font-size: 2.5rem;
  letter-spacing: 5px;
  margin-bottom: 2.5rem;
  opacity: 0;
  animation: startup-expo-fadeInUp 1s 0.8s forwards;
  text-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
}

.startup-expo-hero__button {
  background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%);
  color: white;
  border: none;
  padding: 15px 30px;
  font-size: 1.2rem;
  border-radius: 50px;
  cursor: pointer;
  transition: all 0.3s ease;
  opacity: 0;
  animation: startup-expo-fadeInUp 1s 1.2s forwards;
  box-shadow: 0 5px 15px rgba(106, 17, 203, 0.4);
  text-decoration: none;
}

.startup-expo-hero__button:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(106, 17, 203, 0.6);
}

@keyframes startup-expo-fadeInUp {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .startup-expo-hero__title {
    font-size: 3.5rem;
  }
  
  .startup-expo-hero__subtitle {
    font-size: 1.2rem;
  }
}

@media (max-width: 480px) {
  .startup-expo-hero__title {
    font-size: 2.5rem;
    letter-spacing: 2px;
  }
  
  .startup-expo-hero__button {
    padding: 12px 25px;
    font-size: 1rem;
  }
}