.scroll-indicator {
  position: fixed;
  bottom: 120px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}

.chevron {
  position: absolute;
  left: 50%;
  width: 2.1rem;
  height: 0.48rem;
  opacity: 0;
  transform: translateX(-50%) scale(0.3);
  animation: move-chevron 3s ease-out infinite;
}

.chevron:first-child {
  animation: move-chevron 3s ease-out 1s infinite;
}

.chevron:nth-child(2) {
  animation: move-chevron 3s ease-out 2s infinite;
}

.chevron:before,
.chevron:after {
  content: '';
  position: absolute;
  top: 0;
  height: 100%;
  width: 50%;
  background: #6738b2;
}

.chevron:before {
  left: 0;
  transform: skewY(30deg);
}

.chevron:after {
  right: 0;
  width: 50%;
  transform: skewY(-30deg);
}

@keyframes move-chevron {
  25% {
    opacity: 1;
  }
  33.3% {
    opacity: 1;
    transform: translateX(-50%) translateY(2.28rem);
  }
  66.6% {
    opacity: 1;
    transform: translateX(-50%) translateY(3.12rem);
  }
  100% {
    opacity: 0;
    transform: translateX(-50%) translateY(4.8rem) scale(0.5);
  }
}