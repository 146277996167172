.newnav {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 1);
  backdrop-filter: blur(8px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.newnav-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem 2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.newnav-logo {
  text-decoration: none;
  display: flex;
  align-items: center;
  font-family: 'Orbitron', sans-serif;
}

.logo-image {
  height: 45px; /* Adjusted height for logo */
  width: auto;
  display: block;
  filter: drop-shadow(0 0 2px rgba(139, 92, 246, 0.5)); /* Optional: adds a subtle purple glow */
}

.newnav-links {
  display: flex;
  gap: 2rem;
  align-items: center;
}

.nav-link {
  color: white;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease;
  position: relative;
}

.nav-link::after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: -4px;
  left: 0;
  background: linear-gradient(90deg, #8B5CF6, #EC4899);
  transition: width 0.3s ease;
}

.nav-link:hover::after {
  width: 100%;
}

.contact-btn {
  background: linear-gradient(90deg, #8B5CF6, #EC4899);
  padding: 0.5rem 1.5rem;
  border-radius: 9999px;
  transition: transform 0.3s ease;
}

.contact-btn:hover {
  transform: translateY(-2px);
}

.contact-btn::after {
  display: none;
}

.mobile-menu-button {
  display: none;
  background: none;
  border: none;
  color: white;
  cursor: pointer;
  padding: 0.5rem;
}

@media (max-width: 768px) {
  .mobile-menu-button {
    display: block;
  }
  
  .logo-image{
    height: 30px;
  }
  
  .newnav-links {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    flex-direction: column;
    background: rgba(0, 0, 0, 0.95);
    padding: 1rem;
    gap: 1rem;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  }

  .newnav-links.active {
    display: flex;
  }

  .nav-link {
    width: 100%;
    text-align: center;
    padding: 0.5rem;
  }

  .contact-btn {
    width: auto;
    margin: 0.5rem auto;
  }
}